// import

import type {PageNode} from '@arc/hub-model';
import type {GetStaticProps} from 'next';

// import

import {revalidate} from 'libs/constants';
import db from 'libs/db';
import {PagePage} from 'modules/Page';

// types

type Props = {
  page: PageNode;
};

type Query = {
  slug: string[];
};

// component

export default function PageView(props: Props) {
  return (
    <PagePage {...props} />
  );
}

// data

export const getStaticProps: GetStaticProps<Props, Query> =
  async ({preview}) => {
    const [page] = await Promise.all([
      db.readPage('home', {preview}),
    ]);

    if (!page) {
      return {notFound: true};
    }

    return {
      revalidate,
      props: {page},
    };
  };
